<template>
  <div class="clockin-record" >
    <h2>打卡统计</h2>
    <p>本月已打卡{{clockedDay}}天</p>
    <!-- <p>本月截止昨天有<span style="color: red">{{clockedUserCount}}</span>位用户全勤</p> -->
    <div class="records-box" ref="records_box" :style="`width:${bodyWidth}px;`">
      <div class="records-body" >
        <div @click="changeActiveDay(date)" :style="`width:${itemWidth}px;`" :class="activeDay.format('D') === date.format('D') ? 'record-item record-item-activate' :'record-item'" v-for="date in dateArray" :key="date.format('YYYY-MM-DD')">
          <div class="item-content" :style="`width:${itemWidth - 10}px;height:${itemWidth - 10}px`" >
            <span>{{date.format('M月')}}</span>
            <span>{{date.format('D')}}</span>
            <img class="icon" src="@/assets/clocked0.png" v-if="recordDataMap[today.format('D')] && recordDataMap[date.format('D')].length === 0 && today.isAfter(date)"></img>
            <span class="icon icon-warn" v-if="recordDataMap[today.format('D')] && recordDataMap[date.format('D')].length === 1"></span>
            <img class="icon" src="@/assets/clocked2.png" v-if="recordDataMap[today.format('D')] && recordDataMap[date.format('D')].length > 1"></img>
          </div>
          <p v-if="recordDataMap[today.format('D')] && recordDataMap[date.format('D')].length === 0">{{today.isAfter(date) ? '缺卡' : ''}}</p>
          <p v-if="recordDataMap[today.format('D')] && recordDataMap[date.format('D')].length === 1">打卡1次</p>
          <p v-if="recordDataMap[today.format('D')] && recordDataMap[date.format('D')].length > 1">已打卡</p>
        </div>
      </div>
    </div>
    <div class="activate_data">
      <h2>{{activeDay.format('MM月DD日')}}打卡</h2>
      <img style="margin-right: 10px" :src="record.url" alt="" v-for="record in recordDataMap[activeDay.format('D')]">
    </div>
    <mt-button v-if="recordDataMap[today.format('D')] && recordDataMap[today.format('D')].length < 2" class="user-btn" @click="goClockIn" type="primary">点击打卡</mt-button>
    <mt-button v-else  class="user-btn info" type="primary">今天已完成打卡</mt-button>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import _ from 'lodash'
import moment from 'moment'
export default {
  name: 'UsersList',
  data() {
    return {
      dateArray: [],
      recordDataMap: {},
      today: moment(),
      activeDay: moment(),
      futureDayHide: '',
      clockedUserCount: 0,
      clockedDay: 0,
      itemWidth: 70,
      bodyWidth: 300
    }
  },
  components: {
  },
  computed: mapState({
    user: state => state.user.user
  }),
  beforeMount () {
    this.getClockinRecords()
  },
  mounted() {
    this.dateArray = this.getDate()
    const clientWidth = document.body.clientWidth
    this.bodyWidth = parseInt(clientWidth / this.itemWidth) * this.itemWidth
  },
  methods: {
    getDate() {
      let dateStart = moment().startOf('month')
      let dateEnd = moment().endOf('month')
      let timeValues = []

      while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
        let curDay = _.cloneDeep(dateStart)
        this.recordDataMap[curDay.format('D')] = []
        timeValues.push(curDay)
        dateStart.add(1, 'day')
      }
      return timeValues
    },
    getTime(time) {
      if (!time) {
        return ''
      }
      return moment(time).format('HH:mm')
    },
    changeActiveDay (createdAt) {
      this.activeDay = createdAt
    },
    getClockinRecords () {
      this.$store.httpDispatch({})('getClockinRecords')
        .then((res) => {
          if (res.data.code === 0) {
            this.list = res.data.data.records
            this.clockedUserCount = res.data.data.clockedUserCount
            const createdAtArr = this.list.map((record) => record.createdAt)
            this.clockedDay = [...new Set(createdAtArr.map((createdAt) => moment(createdAt).format('D')))].length
            this.list.forEach((record) => {
              this.recordDataMap[moment(record.createdAt).format('D')].push(record)
            })
            if (this.recordDataMap[this.today.format('D')].length === 0) {
              this.$router.push({
                path: '/clockin/today'
              })
            }
          }
        })
    },
    goClockIn () {
      this.$router.push({
        path: '/clockin/today'
      })
    }
  }
}
</script>

<style lang="less">
  .clockin-record {
    padding: 20px;
    background: #fff;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    h2, p {
      text-align: left;
    }
    h2 {
      margin-top: 0;
    }
    .records-box {
      margin: 0 auto;
      position:relative;
      left: -10px;
      .records-body {
        height: 220px;
        overflow-y: scroll;
        .record-item-activate {
          .item-content {
            background: #3078EF;
            color: #fff;
          }
          p {
            color: #3078ef;
          }
        }
        .record-item {
          float: left;
          padding: 5px 5px 5px 5px;
          box-sizing: border-box;
          p {
            text-align: center;
            margin: 0 0;
            font-size: 10px;
            height: 15px;
          }
          .item-content {
            border-radius: 50%;
            border: 1px solid #ccc;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 7px 0;
            span {
              flex: 1;
            }
            .icon {
              position: absolute;
              right: 0;
              top: 0px;
              background: #00B256;
              color: #fff;
              border-radius: 50%;
              width: 15px;
              height: 15px;
              line-height: 15px;
              text-align: center;
            }
            .icon-warn {
              background: #FFC933;
            }
            .icon-error {
              background: #D01F2D;
            }
          }
        }

      }
    }
    .activate_data {
      text-align: left;
      min-height: 200px;
      margin-top: 30px;
      img {
        width: 40%;
      }
    }
    .user-btn {
      height: 35px;
      line-height: 35px;
      background: #55BACF;
      width: 100%;
      border-radius: 5px;
      margin-top: 30px;
      margin-bottom: 50px;
    }
    .info {
      background: #CBCBCB;
    }
  }
</style>
