<template>
  <div class="clock-in-page">
    <h2>每日打卡</h2>
    <p>坚持每天陪伴孩子打卡，我们会选出每月打卡次数最多评选为“陪伴之星”，并送出小礼物哦！</p>
    <br />
    <br />
    <h2>打卡规则</h2>
    <p>拍摄家长陪伴孩子的照片，每天打卡一至两次，不能重复上传同一张图片哦。</p>
    <p style="margin-top: 200px">点击下方图标，选择或拍摄图片后“提交”</p>
    <div class="upload-button">
      <div @click="chooseImage" v-if="!localData" class="upload-btn">
        <div><img src="@/assets/photo.png" alt="" /></div>
        <p>图片</p>
      </div>
      <img class="uploaded-image" v-else @click="chooseImage" :src="localData" alt="">
    </div>
    <mt-button v-if='!uploaded' class="user-btn" @click="uploadImage" type="primary">提交</mt-button>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import { Indicator, Toast } from 'mint-ui';
export default {
  name: 'clock-in-page',
  data() {
    return {
      key: '',
      serverId: '',
      localData: '',
      uploaded: false
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: function () {
  },
  methods: {
    showImage (key) {
      this.key = key
      this.popupVisible = true
    },
    uploadImage () {
      if (!this.serverId) {
        return Toast({
          message: '请选择上传图片',
          duration: 3000
        })
      }
      Indicator.open({
        text: '正在上传...',
        spinnerType: 'fading-circle'
      })
      this.$store.httpDispatch({})('uploadImageByClockin', {
        serverId: this.serverId
      }).then(() => {
        Indicator.close()
        this.uploaded = true
        this.$router.push({
          path: '/clocked/list'
        })
      }).catch((err) => {
        Indicator.close()
        console.log(err)
        Toast({
          message: '请求失败',
          duration: 3000
        })
      })
    },
    getLocalImgData(localId) {
      let that = this
      wx.getLocalImgData({
        localId: localId, // 图片的localID
        success: function (res) {
          console.log(res.localData)
          var localData = res.localData // localData是图片的base64数据，可以用img标签显示
          if (localData.substr(0, 5) != 'data:') {
            localData = 'data:image;base64,' + localData
          }
          that.localData = localData
        },
        fail: function (err) {
          console.log(err)
        }
      })
    },
    // wx upload image
    chooseImage () {
      const that = this
      console.log('开始选择')
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        success: function (res) {
          var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
          console.log('已选择')
          console.log(localIds)
          let length = localIds.length
          let i = 0
          const upload = () => {
            if (i >= length) {
              return
            }
            that.getLocalImgData(localIds[i])
            wx.uploadImage({
              localId: localIds[i], // 图片的localID
              isShowProgressTips: 1, // 默认为1，显示进度提示
              success: function (res) {
                console.log('已上传到微信服务器')
                console.log(res)
                that.serverId = res.serverId
                i++
                upload()
              }
            })
          }
          upload()
        },
        error: function (error) {
          console.log(error)
          alert(error)
        }
      })
    }
  }
}
</script>
<style lang="less">
  .clock-in-page {
    padding: 20px;
    background: #fff;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    .uploaded-image {
      width: 200px;
      height: 200px;
    }
    h2 {
      margin: 0 0;
      text-align: left;
      font-size: 21px;
    }
    p {
      text-align: left;
      margin: 0 0;
    }
    .upload-btn {
      margin: 30px auto;
      width: 200px;
      img {
        margin: 0 auto 10px;
        width: 50px;
      }
      p {
        text-align: center;
        font-size: 19px
      }
    }
    .user-btn {
      height: 35px;
      line-height: 35px;
      background: #55BACF;
      width: 100%;
      border-radius: 5px;
      margin-top: 30px;
    }
  }
</style>
